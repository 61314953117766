<template>
  <div class="Calculator">
    <div class="Calculator_h">
      <div class="Calculator_bac"></div>
      <div class="Calculator_title">
        <h3>薪税计算器</h3>
        <p>智能分配，为您节税</p>
      </div>
      <div class="Calculator_box">
        <div class="Calculator_content">
          <div class="add_box">
            <van-button icon="plus" plain type="info" @click="show = true"
              >添加专项扣除</van-button
            >
            <van-dialog
              v-model="show"
              title="选择专项扣除项"
              show-cancel-button
              @confirm="confirm"
            >
              <div class="special_list">
                <div
                  v-for="(item, index) in dataInfo"
                  :key="item.name"
                  :class="
                    item.checked
                      ? 'special_list_box checked'
                      : 'special_list_box'
                  "
                  @click="addClick(item, index)"
                >
                  <transition name="van-fade">
                    <van-icon name="bookmark" v-if="item.checked" />
                  </transition>
                  <span>{{ item.name }}</span>
                </div>
                <div class="special_list_box prohibit">大病医疗</div>
              </div>
              <div class="remark">
                <p>住房贷款利息扣除与住房租金扣除同时至能享受一项</p>
                <p>大病医疗扣除需次年纳税人自行扣除，暂不支持按月计算</p>
                <p>职业资格继续教育暂不支持分月定额扣除标签</p>
              </div>
            </van-dialog>
          </div>
          <div class="special_list">
            <div
              class="special_box"
              v-for="(item, index) in list"
              :key="item.name"
            >
              <div class="special_box_name">{{ item.name }}</div>
              <div class="special_box_operate">
                <div class="money">{{ item.num || "0.00" }}/月</div>
                <div class="edit" @click="reviseClick(item, index)">修改</div>
                <div class="delete" @click="deleteClick(item, index)">删除</div>
              </div>
            </div>
            <van-popup v-model="showPicker" round position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            /></van-popup>
            <div class="elder_dialog">
              <van-dialog
                v-model="elderShow"
                title="专项扣除项-赡养老人"
                show-cancel-button
                @confirm="elderOnConfirm"
              >
                <div>
                  <div>
                    <van-field name="radio" label="扣除人条件:">
                      <template #input>
                        <van-radio-group
                          v-model="elderRadio"
                          direction="horizontal"
                          @change="elderChange"
                        >
                          <van-radio name="1" icon-size="15px"
                            >独生子女</van-radio
                          >
                          <van-radio name="2" icon-size="15px"
                            >非独生子女</van-radio
                          >
                        </van-radio-group>
                      </template>
                    </van-field>
                    <van-field
                      :readonly="elderRadio == 1"
                      v-model="elderProportion"
                      @blur="onblurElder"
                      center
                      clearable
                      label="分摊比例"
                      placeholder="请输入"
                    >
                      <template #button>
                        <span>%</span>
                      </template>
                    </van-field>
                    <van-field
                      readonly
                      v-model="elderAmountNum"
                      center
                      clearable
                      label="分摊金额"
                      placeholder="请输入"
                    >
                    </van-field>
                  </div>
                </div>
              </van-dialog>
            </div>
          </div>
          <div class="ECharts_box">
            <div id="main"></div>
            <div class="total">
              折扣金额总合<br />￥{{ totalNum || "0.00" }}/月
            </div>
          </div>

          <div class="type_list">
            <div class="type_list_box" v-for="item in list" :key="item.name">
              <span :style="{ background: item.color }"></span>
              <i>{{ item.name }}</i>
            </div>
          </div>
          <div class="bottom_but">
            <van-button type="info" @click="$router.go(-1)">确认</van-button>
          </div>
        </div>
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import {
  Button,
  Dialog,
  Icon,
  Popup,
  Picker,
  Field,
  RadioGroup,
  Radio,
} from "vant";
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { findAllDeductionIndividual } from "@/api/Calculator";
import copyrightIp from "@/components/copyrightIp";
echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);
export default {
  name: "socialSecurity",
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Dialog.Component.name]: Dialog.Component,
    copyrightIp,
  },

  data() {
    return {
      number: "",
      totalNum: "", //折扣金额总合
      show: false, // 添加专项
      elderShow: false, //赡养老人
      showPicker: false, // 修改专项
      dataInfo: [
        {
          name: "子女教育",
          checked: false,
          color: "#66CCFF",
          child: [
            {
              text: "不符合，扣除0元",
              value: "0",
            },
            {
              text: "一个孩子，夫妻各扣除1000元",
              value: "1000",
            },
            {
              text: "一个孩子，仅一方扣除2000元",
              value: "2000",
            },
            {
              text: "两个个孩子，夫妻各扣除2000元",
              value: "2000",
            },
            {
              text: "两个个孩子，仅一方扣除4000元",
              value: "4000",
            },
          ],
        },
        {
          name: "继续教育",
          checked: false,
          color: "#00CC66",
          child: [
            {
              text: "不符合，扣除0元",
              value: "0",
            },
            {
              text: "学历教育扣除400元",
              value: "400",
            },
          ],
        },
        {
          name: "住房租金",
          checked: false,
          color: "#FF9966",
          child: [
            {
              text: "不符合，0扣除",
              value: "0",
            },
            {
              text: "直辖市、省会城市、计划单列及国务院确定的其他城市 1500元",
              value: "1500",
            },
            {
              text: "市辖区户籍人口超过100万的城市  1100元",
              value: "1100",
            },
            {
              text: "市辖区户籍人口不超过100万的城市 800元",
              value: "800",
            },
          ],
        },
        {
          name: "住房贷款利息",
          checked: false,
          color: "#FA6887",
          child: [
            {
              text: "不符合，0扣除",
              value: "0",
            },
            {
              text: "夫妻个扣除500元",
              value: "500",
            },
            {
              text: "仅有一方扣除1000元",
              value: "1000",
            },
          ],
        },
        {
          name: "赡养老人",
          checked: false,
          color: "#99FF33",
          child: [
            {
              text: "独生子女",
              value: "3000",
            },
            {
              text: "非独生子女",
              value: "3000",
            },
          ],
        },
        {
          name: "婴幼儿照护",
          checked: false,
          color: "#9900FF",
          child: [
            {
              text: "不符合，0扣除",
              value: "0",
            },
            {
              text: "夫妻个扣除1000元",
              value: "1000",
            },
            {
              text: "仅有一方扣除2000元",
              value: "2000",
            },
          ],
        },
      ],
      list: [
        //  {   name:"",
        //     num:""}
      ], // 专项列表
      reviseIndex: null, // 修改专项索引
      columns: [
        { text: "杭州", name: "我是名字", value: "我是value", id: "1" },
        { text: "宁波", name: "我是名字", value: "我是value", id: "2" },
        { text: "温州", name: "我是名字", value: "我是value", id: "3" },
        { text: "绍兴", name: "我是名字", value: "我是value", id: "4" },
      ],
      elderRadio: "1",
      elderProportion: 100, // 赡养老人分摊比例
      elderAmountNum: "", //  赡养老人分摊金额
      elderAmount: "", //  赡养老人分摊金额（基数）
      myChart: null,
      option: {},
    };
  },
  mounted() {
    this.initECharts();

    let totalNum = JSON.parse(sessionStorage.getItem("special")) || "";
    if (totalNum) {
      this.totalNum = totalNum;
      this.dataInfo =
        JSON.parse(sessionStorage.getItem("specialDataInfo")) || "";
      this.list = JSON.parse(sessionStorage.getItem("specialList")) || "";
      this.changECharts();
    } else {
      this.getFindAllTDeductionIndividual();
    }
  },
  beforeDestroy() {
    this.myChart = null;
    if (Number(this.totalNum)) {
      // const obj = JSON.parse(JSON.stringify(row));
      sessionStorage.setItem("special", this.totalNum);
      sessionStorage.setItem("specialDataInfo", JSON.stringify(this.dataInfo));
      sessionStorage.setItem("specialList", JSON.stringify(this.list));
    } else {
      sessionStorage.removeItem("special");
      sessionStorage.removeItem("specialDataInfo");
      sessionStorage.removeItem("specialList");
    }
  },
  methods: {
    // 计算总合
    computeTotal() {
      this.totalNum = 0;
      this.list.forEach((item) => {
        if (item.num) {
          this.totalNum += Number(item.num);
        }
      });
    },
    // 赡养老人弹框确认
    elderOnConfirm() {
      this.list[this.reviseIndex].num = this.elderAmountNum;
      this.computeTotal();
      this.changECharts();
    },
    // 赡养老人失焦事件
    onblurElder() {
      this.elderAmountNum = (this.elderAmount * this.elderProportion) / 100;
      if (this.elderAmountNum > this.elderAmount) {
        this.elderProportion = 100;
        this.elderAmountNum = this.elderAmount;
      }
      if(this.elderRadio == 2) {
        if(this.elderProportion > 50){
          this.elderProportion = 50;
        }
        this.elderAmountNum = (this.elderAmount * this.elderProportion) / 100;
        if (this.elderAmountNum > this.elderAmount) {
            this.elderProportion = 100;
            this.elderAmountNum = this.elderAmount;
        }
      }
    },
    // 赡养老人单选
    elderChange() {
      if (this.elderRadio == 1) {
        this.elderAmountNum = this.elderAmount;
        this.elderProportion = 100;
      }else if(this.elderRadio == 2) {
          this.elderProportion = 50;
          this.elderAmountNum = (this.elderAmount * this.elderProportion) / 100;
          if (this.elderAmountNum > this.elderAmount) {
              this.elderProportion = 100;
              this.elderAmountNum = this.elderAmount;
          }
      }
    },
    // 删除
    deleteClick(data, index) {
      this.dataInfo.find((item) => {
        if (item.name === data.name) {
          item.checked = false;
        }
      });
      this.list[index].num = "0.00";
      this.list.splice(index, 1);
      this.computeTotal();
      this.changECharts();
    },
    // 修改
    reviseClick(data, index) {
      this.columns = data.child;
      this.reviseIndex = index;
      if (data.name === "赡养老人") {
        this.elderShow = true;
        this.elderAmount = data.child[0].value;
        this.elderAmountNum = data.child[0].value;
      } else {
        this.showPicker = true;
      }
    },
    // 修改弹框确认
    onConfirm(data) {
      this.list[this.reviseIndex].num = data.value;
      this.showPicker = false;
      this.computeTotal();
      this.changECharts();
    },
    //   添加专项
    addClick(data) {
      if (
        data.name === "住房租金" &&
        !data.checked &&
        this.dataInfo[3].checked
      ) {
        Dialog.confirm({
          message: "住房租金与住房贷款利息不可同时选中，是否确认更换",
          confirmButtonText: "继续",
          cancelButtonText: "取消",
        })
          .then(() => {
            data.checked = true;
            this.dataInfo[3].checked = false;
            return;
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      } else if (
        data.name === "住房贷款利息" &&
        !data.checked &&
        this.dataInfo[2].checked
      ) {
        Dialog.confirm({
          message: "住房租金与住房贷款利息不可同时选中，是否确认更换",
          confirmButtonText: "继续",
          cancelButtonText: "取消",
        })
          .then(() => {
            data.checked = true;
            this.dataInfo[2].checked = false;
            return;
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      } else {
        data.checked = !data.checked;
      }
    },
    // 选择专项确认
    confirm() {
      this.list = this.dataInfo.filter((item) => item.checked);
    },
    changECharts() {
      let arr = [];
      this.list.forEach((item) => {
        arr.push({
          value: item.num,
          name: item.name,
          itemStyle: {
            color: item.color,
          },
        });
      });
      this.option.series[0].data = arr;

      this.option && this.myChart.setOption(this.option);
    },
    initECharts() {
      var chartDom = document.getElementById("main");
      this.myChart = echarts.init(chartDom);
      this.option = {
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 1,
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 100,
                name: "",
                itemStyle: {
                  color: "#66CCFF",
                },
              },
            ],
          },
        ],
      };

      this.option && this.myChart.setOption(this.option);
    },
    // 获取获取个税专项扣除
    getFindAllTDeductionIndividual() {
      findAllDeductionIndividual().then((res) => {
        let tchildrensEducationList = res.data.data.tchildrensEducationList; // 子女教育
        let tcontinuingEducationList = res.data.data.tcontinuingEducationList; // 继续教育
        let thousingLoanInterestList = res.data.data.thousingLoanInterestList; // 住房贷款利息
        let thousingRentList = res.data.data.thousingRentList; // 住房租金
        let tsupportElderlyList = res.data.data.tsupportElderlyList; // 赡养老人
        let tinfantCareList = res.data.data.tinfantCareList; // 赡养老人

        this.dataInfo[0].child.forEach((item, index) => {
          item.text = tchildrensEducationList[index].childrensEducation;
          item.value = tchildrensEducationList[index].childrensEducationValue;
        });
        this.dataInfo[1].child.forEach((item, index) => {
          item.text = tcontinuingEducationList[index].continuingEducation;
          item.value = tcontinuingEducationList[index].continuingEducationValue;
        });
        this.dataInfo[2].child.forEach((item, index) => {
          item.text = thousingRentList[index].housingRent;
          item.value = thousingRentList[index].housingRentValue;
        });
        this.dataInfo[3].child.forEach((item, index) => {
          item.text = thousingLoanInterestList[index].housingLoanInterest;
          item.value = thousingLoanInterestList[index].housingLoanInterestValue;
        });
        this.dataInfo[4].child.forEach((item, index) => {
          item.text = tsupportElderlyList[index].supportElderlyOnlyChild;
          item.value =
            tsupportElderlyList[index].supportElderlyNonOnlyChildValue;
        });
        this.dataInfo[5].child.forEach((item, index) => {
          item.text = tinfantCareList[index].infantCareOption;
          item.value = tinfantCareList[index].infantCareOptionValue;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Calculator {
  width: 100%;
  height: 100%;
  position: relative;
  .Calculator_h {
    min-height: calc(100% - 44px);
  }
  // padding-bottom: 70px;
  .Calculator_bac {
    width: 100%;
    height: 250px;
    background-image: url("../../../assets/img/Utilities1_Moon_01.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .Calculator_title {
    padding: 10px 10px 0;
    color: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  .Calculator_box {
    width: 100%;
    padding: 10px;

    .Calculator_content {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
      .bottom_but {
        background: #fff;
        padding: 10px;
        .van-button {
          width: 100%;
          border-radius: 6px;
        }
      }
    }
  }
}
.add_box {
  .van-button {
    width: 100%;
  }
  .special_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    padding: 30px 10px;
    .checked {
      box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    }
    .prohibit {
      background: #e3e3e3;
    }
    .special_list_box {
      position: relative;
      text-align: center;
      transition: 0.3s;
      // box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
      padding: 15px 5px;
      font-size: 13px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #ebebeb;
      .van-icon {
        font-size: 12px;
        position: absolute;
        top: -2px;
        left: 2px;
        color: red;
        transition: 0.5s;
      }
    }
  }
  .remark {
    padding-bottom: 5px;
    padding-left: 10px;
    padding: 0 10px 5px;
    font-size: 12px;
    p {
      line-height: 19px;
      padding-left: 8px;
      position: relative;
      &::before {
        content: "*";
        position: absolute;
        top: -2px;
        left: 0;
        color: red;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
.special_list {
  .special_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 50px;
    border-bottom: 1px solid #f1f1f1;
    padding-left: 10px;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 12px;
      left: 3px;
      width: 2px;
      height: 26px;
      background: #66ccff;
    }
    &:nth-child(2) {
      &::before {
        background: #00cc66;
      }
    }
    &:nth-child(3) {
      &::before {
        background: #ff9966;
      }
    }
    &:nth-child(4) {
      &::before {
        background: #ff0033;
      }
    }
    &:nth-child(5) {
      &::before {
        background: #99ff33;
      }
    }
    .special_box_operate {
      display: flex;
      font-weight: bold;
      .edit {
        color: #66ccff;
        padding: 0 10px 0 15px;
      }
      .delete {
        color: red;
        padding: 0 10px;
      }
    }
  }
  .elder_dialog {
    .van-dialog {
      width: 340px;
    }
  }
}
.ECharts_box {
  display: flex;
  align-items: center;
  #main {
    // width: 180px;
    // min-width: 180px;
    flex: 1;
    height: 180px;
  }
  .total {
    flex: 1;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
}
.type_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  .type_list_box {
    display: flex;
    align-items: center;
    // justify-content: center;
    font-size: 13px;
    padding-bottom: 5px;
    span {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 2px;
    }
    // &:nth-child(1) {
    //   span {
    //     background: #33cc99;
    //   }
    // }
    // &:nth-child(2) {
    //   span {
    //     background: #5470c6;
    //   }
    // }
    // &:nth-child(3) {
    //   span {
    //     background: #ff6699;
    //   }
    // }
    // &:nth-child(4) {
    //   span {
    //     background: #ffcc33;
    //   }
    // }
  }
}
</style>
